import React, { useState } from "react";
import axios from "axios";
import "./Register.css";
import Form from "react-bootstrap/Form";
import Logo from "../../Assets/suprabadh Logo 1.png";
import { useNavigate } from "react-router-dom";
import Insurance from "../../Assets/insurancelogo.jpg";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    mobile1: "",
    mobile2: "",
    mobile3: "",
    mobile4: "",
    gender: "",
    address: "",
    aadhaar_no: "",
    occupation: "",
    nominee_name: "",
    nominee_relation: "",
    home_name: "",
    home_no: "",
    home_address: "",
    district: "",
  });

  const [errors, setErrors] = useState({
    aadhaar_no: "",
  });

  const districts = [
    "Thiruvananthapuram",
    "Kollam",
    "Pathanamthitta",
    "Alappuzha",
    "Kottayam",
    "Idukki",
    "Ernakulam",
    "Thrissur",
    "Palakkad",
    "Malappuram",
    "Kozhikode",
    "Wayanad",
    "Kannur",
    "Kasaragod",
    // Add other districts here
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "aadhaar_no") {
      // Remove non-numeric characters and restrict length to 12
      const newValue = value.replace(/\D/g, "").slice(0, 12);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name === "nominee_name") {
      // Remove non-alphabetic characters
      const newValue = value.replace(/[^a-zA-Z\s]/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleKeyPress = (e) => {
    const { name } = e.target;
    if (name === "nominee_name") {
      // Allow only alphabetic characters and spaces
      const charCode = e.charCode;
      if (
        (charCode < 65 || charCode > 90) && // uppercase letters
        (charCode < 97 || charCode > 122) && // lowercase letters
        charCode !== 32 // space
      ) {
        e.preventDefault();
      }
    }
  };

  const navigate = useNavigate();

  const Signup = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({
      aadhaar_no: "",
    });

    // Validate Aadhaar number
    if (formData.aadhaar_no.length !== 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        aadhaar_no: "Aadhaar number must be exactly 12 digits long",
      }));
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/register",
        formData
      );
      console.log(response.data);
      navigate("/Login");
    } catch (error) {
      console.error("There was an error registering the user!", error);
    }
  };

  return (
    <div className="container-fluid register">
      <form onSubmit={Signup}>
        <div className="form-header">
          <img src={Logo} className="img" alt="Logo" />
          <img src={Insurance} alt="Insurance" />
        </div>  
        <div className="header-details">
          <h4>सबका निर्भय भारत - Sabka Nirbhay Bharat</h4>
          <h4>ACCIDENTAL & HOME INSURANCE</h4>
        </div>
        <div
          style={{
            width: "100%",
            height: "3px",
            backgroundColor: "red",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></div>

        <h4 style={{ color: "red", fontWeight: "700" }}>Personal Details</h4>

        {[
          {
            id: "name",
            label: "Name",
            type: "text",
            placeholder: "Enter your name",
            required: true,
            maxLength: undefined,
          },
          {
            id: "dob",
            label: "Date of Birth (DD/MM/YYYY)",
            type: "text",
            placeholder: "Enter date of birth",
            required: true,
            maxLength: undefined,
          },
          {
            id: "address",
            label: "Address & Pincode",
            type: "text",
            placeholder: "Enter address and pincode",
            required: true,
            maxLength: undefined,
          },
          {
            id: "mobile1",
            label: "Mobile Number",
            type: "text",
            placeholder: "Enter your mobile number",
            required: true,
            maxLength: 10,
          },
          {
            id: "mobile2",
            label: "Mobile Number",
            type: "text",
            placeholder: "Enter your mobile number",
            required: false,
            maxLength: 10,
          },
          {
            id: "mobile3",
            label: "Mobile Number (optional)",
            type: "text",
            placeholder: "Enter your mobile number (optional)",
            required: false,
            maxLength: 10,
          },
          {
            id: "mobile4",
            label: "Mobile Number (optional)",
            type: "text",
            placeholder: "Enter your mobile number (optional)",
            required: false,
            maxLength: 10,
          },
          {
            id: "email",
            label: "Email Id",
            type: "text",
            placeholder: "Enter your email id",
            required: false,
            maxLength: undefined,
          },
          {
            id: "aadhaar_no",
            label: "Aadhaar No",
            type: "text",
            placeholder: "Enter Aadhaar number",
            required: true,
            maxLength: 12,
          },
          {
            id: "occupation",
            label: "Occupation",
            type: "text",
            placeholder: "Enter your occupation",
            required: false,
            maxLength: undefined,
          },
          {
            id: "nominee_name",
            label: "Nominee Name",
            type: "text",
            placeholder: "Enter nominee's name",
            required: false,
            maxLength: undefined,
          },
          {
            id: "nominee_relation",
            label: "Nominee Relation",
            type: "text",
            placeholder: "Enter relation with nominee",
            required: false,
            maxLength: undefined,
          },
          {
            id: "home_name",
            label: "Home Name",
            type: "text",
            placeholder: "Enter home name",
            required: false,
            maxLength: undefined,
          },
          {
            id: "home_no",
            label: "Home No",
            type: "text",
            placeholder: "Enter home number",
            required: false,
            maxLength: undefined,
          },
          {
            id: "home_address",
            label: "Home Address",
            type: "text",
            placeholder: "Enter home address",
            required: false,
            maxLength: undefined,
          },
        ].map(({ id, label, type, placeholder, required, maxLength }) => (
          <div className="forminput-sec" key={id}>
            <Form.Label
              htmlFor={id}
              style={{ width: "300px", fontSize: "16px" }}
            >
              {label}:
            </Form.Label>
            <Form.Control
              type={type}
              id={id}
              placeholder={placeholder}
              name={id}
              value={formData[id]}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              required={required}
              maxLength={maxLength}
              pattern={id === "aadhaar_no" ? "[0-9]*" : undefined} // Ensure only numeric values are accepted for Aadhaar number
            />
            {id === "aadhaar_no" && errors.aadhaar_no && (
              <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                {errors.aadhaar_no}
              </div>
            )}
          </div>
        ))}

        <div className="forminput-sec">
          <Form.Label
            htmlFor="gender"
            style={{ width: "300px", fontSize: "16px" }}
          >
            Gender:
          </Form.Label>
          <Form.Control
            as="select"
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </Form.Control>
        </div>

        <div className="forminput-sec">
          <Form.Label
            htmlFor="district"
            style={{ width: "300px", fontSize: "16px" }}
          >
            District:
          </Form.Label>
          <Form.Control
            as="select"
            id="district"
            name="district"
            value={formData.district}
            onChange={handleChange}
            required
          >
            <option value="">Select District</option>
            {districts.map((district, index) => (
              <option key={index} value={district}>
                {district}
              </option>
            ))}
          </Form.Control>
        </div>

        <div className="submit-btn">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Register;
