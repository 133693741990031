import React, { useEffect, useState } from "react";
import "./Login.css";
import Form from "react-bootstrap/Form";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import Logo from "../../Assets/suprabadh Logo 1.png";
const Login = (referralid) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { referral_id } = useParams();
  useEffect(() => {
    if (referral_id) {
      console.log("Referral ID:", referral_id);
      // Handle referral ID logic here
    }
  }, [referral_id]);
  console.log("Login component loaded");
  console.log("Referral ID:", referral_id);

  const useLastSegment = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };
  console.log(useLastSegment(), "useLastSegment");

  //................Login Api.................//

  console.log(referralid, "idzzz");

  const LoginApi = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      // Send POST request to the API
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/user/mailsend",
        {
          user_email: email, // Email to be sent in the request
        }
      );

      console.log(response.data); // Log the response for debugging

      // Check if the response indicates success
      if (response.data.result === true) {
        alert(response.data.message); // Show success message
        navigate("/otp", { state: { email: email, referral_id: referral_id } });
      } else {
        alert(response.data.message || "Email not registered"); // Show specific message from response or fallback
      }
    } catch (error) {
      console.error("Error sending request:", error); // Log the error

      // Show a generic error message if the request itself fails
      alert("An error occurred while sending the request. Please try again.");
    }
  };

  return (
    <>
      <div className="main">
        <div style={{marginTop:-20}}>
          <img src={Logo} width={220} height={100} className="logostyle" alt="logo" />
        </div>
        
        <div className="text-center move-up">
          <h2 className="titletext ">APNA DESH TEA</h2>
        </div>
        <div className="container-fluid login ">
          <form onSubmit={LoginApi}>
            <h4>Log In</h4>

            <div className="log-inputs">
              <Form.Label htmlFor="email" className="Enter">
                Enter Mail Id
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                id="email"
                placeholder="Enter your Mail id"
                className="height"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button type="submit">Submit</button>

            <NavLink to="/register" id="now">
              <h6>Register Now?</h6>
            </NavLink>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
