import React from "react";
import "./OuterMainpages.css";
import { useNavigate } from "react-router-dom";
import down from "../../Assets/down.gif";
import group from "../../Assets/Group 54.png";
import pdfIcon from "../../Assets/pdf.png";
import pdf from "../../Assets/Insurance.pdf";

import pdf1 from "../../Assets/MedicalInsurance.pdf";
import pdf2 from "../../Assets/GPA.pdf";
import pdf3 from "../../Assets/DeathInsurance.pdf";
import pdf4 from "../../Assets/DisabilityInsurance.pdf";
import pdf5 from "../../Assets/Introduction.pdf";
import pdf6 from "../../Assets/IncomeInsurance.pdf";
import pdf7 from "../../Assets/SupportInsurance.pdf";
import Logo from "../../Assets/suprabadh Logo 1.png";
import Pointer from "../../Assets/pointer.gif";
import Insurance from "../../Assets/Insurancetea.mp4";

function OuterMainpages() {
  const navi = useNavigate();

  return (
    <div className="mainouter">
      <div>
        <img src={Logo} className="logostyle" alt="logo" />
      </div>
      <div className="text-center move-up ">
        <h2 className="titletextOUTERmain">APNA DESH TEA</h2>
      </div>

      <div className="buttonstyles" onClick={() => navi("/Login")}>
        <img
          src={Pointer}
          style={{
            marginTop: "-15px",
            width: "100px",
            height: "100px",
            objectFit: "contain",
          }}
          alt="Pointer"
        />
      </div>

      <div>
        <h1 className="circle-text  move-up">Click to Insurance</h1>
      </div>

      <div className="container">
        <div className="row align-items-center vdostyler">
          <div className="col-lg-8 video-frame">
            <h1 className="stepsinsurance move-up1">Steps for Insurance</h1>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src={Insurance}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-4 leveling">
            <h4 className="stepsinsurance">Sip Suprabadh, Get Protected!</h4>
            <p className="description">
              Sip Suprabadh, Get FREE accident coverage for your family. Protect
              your loved ones with every cup. Thank you for choosing Suprabadh!
            </p>

            <div className="row">
              <div className="col-12 mb-4">
                <h5 className="stepsinsurance">Download Insurance Guideline</h5>
                <div className="d-flex justify-content-center align-items-center">
                  {/* <a href={pdf1} download="Insurancepages.pdf"> */}
                  <img
                    src={down}
                    width={500}
                    height={100}
                    className="object-fit-contain downstyle"
                    alt="down arrow"
                  />
                  {/* </a> */}
                </div>

                {/* TYPES OF INSURANCES */}

                <div className="pdficondiv d-flex justify-content-between">
                  {/* <div className="">
                    <a
                      href={pdf5}
                      download="Introduction.pdf"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </a>
                    <p style={{ fontSize: 10 }}>Introduction</p>
                  </div> */}

                  <div className="text-center move">
                    <a
                      href={pdf}
                      download="Insurance.pdf"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "50px",
                          height: "60px",
                        }}
                      />
                    </a>
                    <p style={{ fontSize: 10 }}>Insurance Guildeline</p>
                  </div>
                  <div className="text-center moves">
                    <a
                      href={pdf2}
                      download="GPA Wordings.pdf"
                      className="d-flex justify-content-center "
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "50px",
                          height: "60px",
                        }}
                      />
                    </a>

                    <p style={{ fontSize: 10 }}>GPA Wordings</p>
                  </div>
                  {/* <div className="">
                    <a
                      href={pdf3}
                      download="HomeInsurance.pdf"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </a>
                    <p style={{ fontSize: 10 }}> Home Insurance</p>
                  </div> */}
                  {/* <div className="">
                    <a
                      href={pdf4}
                      download="DisabilityInsurance.pdf"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </a>
                    <p style={{ fontSize: 10 }}> Disability Insurance</p>
                  </div> */}
                </div>
                {/* <div className="pdficondiv d-flex justify-content-center">
                  <div className="">
                    <a
                      href={pdf6}
                      download="IncomeInsurance.pdf"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </a>
                    <p style={{ fontSize: 10 }}>Income Insurance</p>
                  </div>
                  <div className="">
                    <a
                      href={pdf7}
                      download="SupportInsurance.pdf"
                      className="d-flex justify-content-center"
                    >
                      <img
                        src={pdfIcon}
                        alt="Download PDF"
                        className="img-fluid me-2 pdf-icon d-flex justify-content-center"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </a>
                    <p style={{ fontSize: 10 }}>Support Insurance</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OuterMainpages;
