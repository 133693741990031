import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Homepages.css";
import { Table, Container, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import ShareButtons from "../../Components/ShareButtons/ShareButton";
import ProgressBar from "@ramonak/react-progress-bar";
import Slide1 from "../../Assets/slide1.jpg";
import Slide2 from "../../Assets/slide2.jpg";
import Slide3 from "../../Assets/slide3.jpg";

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [code, setCode] = useState("");
  const [showShareButtons, setShowShareButtons] = useState(false);

  const userName = location.state?.userName;
  const email = location.state?.Email;

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    if (storedUserId) {
      setUserId(storedUserId);
      ListApi(storedUserId);
      setupInactivityTimeout();
    } else {
      console.error("User ID not found in localStorage");
    }
    // Cleanup on component unmount
    return () => clearInactivityTimeout();
  }, []);

  function loginToken() {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }

  const loginUrl = `https://suprabhadamuser.lunarenp.com/Login/${userId}SU${loginToken()}`;
  const shareTitle = "Check out this website!";

  const ListApi = async (userId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/list-quantity",
        { user_id: userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setData(response.data.RedeemCode);
      setList(response.data);
      console.log(response.data, "RedeemCode");
    } catch (error) {
      console.log(error);
    }
  };

  const CouponCode = async (redeemCode) => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Please login");
      return; // Exit the function early if there's no token
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/user/redeemcode",
        { redeem_code: redeemCode },
        { headers: { Authorization: `Bearer ${token}`, user_id: userId } }
      );

      console.log(response.data.message, "RedeemCodeR");
      alert(response.data.message);
      ListApi(userId);
    } catch (error) {
      console.log(error);
    }
  };

  const Sharetonextyear = async (userId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/share-exceed",
        { user_id: userId, email: email, status: "next-year" },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log(response, "RedeemCodez");
      alert(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    CouponCode(code);
    console.log("Code applied:", code);
    setCode("");
  };

  const handleShareButtonClick = () => {
    setShowShareButtons(!showShareButtons);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    navigate("/Login"); // Adjust the route if necessary
  };

  // Inactivity logout logic
  let inactivityTimeout;

  const setupInactivityTimeout = () => {
    clearInactivityTimeout();
    inactivityTimeout = setTimeout(handleLogout, 10 * 60 * 1000); // 10 minutes

    // Reset the timeout on user activity
    window.addEventListener("mousemove", resetInactivityTimeout);
    window.addEventListener("keypress", resetInactivityTimeout);
  };

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    setupInactivityTimeout();
  };

  const clearInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    window.removeEventListener("mousemove", resetInactivityTimeout);
    window.removeEventListener("keypress", resetInactivityTimeout);
  };

  // Calculate the progress bar percentage based on quantity purchased
  const maxThreshold = 10; // Define the maximum threshold for the progress bar
  const quantity = list.quantity || 0; // Default to 0 if quantity is undefined
  const progressPercentage = Math.min(100, (quantity / maxThreshold) * 100);

  // Dynamically set the progress bar color
  const progressBarColor = quantity > 5 ? "#ff0000" : "#4caf50"; // Red if quantity > 5, otherwise green
  const progressBarLabel = quantity > 5 ? "Exceeded" : `${quantity}Kg`;

  return (
    <div className="mainhome">
      <Container className="">
        <div className="row justify-content-center">
          <div className=" ">
            <h2 className="titletextOUTERHome move-up">APNA DESH TEA</h2>
          </div>
        </div>

        <div className="carousel-container">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            interval={3000}
            transitionTime={500}
          >
            <div>
              <img src={Slide1} alt="Slide 1" />
            </div>
            <div>
              <img src={Slide2} alt="Slide 2" />
            </div>
            <div>
              <img src={Slide3} alt="Slide 3" />
            </div>
          </Carousel>
        </div>

        <div className="text-center pt-5">
          <Form onSubmit={handleFormSubmit}>
            <div className="input-button-row">
              <Form.Group controlId="formCode">
                <Form.Control
                  type="text"
                  value={code}
                  className="height"
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter code"
                />
              </Form.Group>
              <Button type="submit" className="btn-primary">
                Apply Code
              </Button>
            </div>
          </Form>
        </div>

        <div className="tablehead">
          <div className="p-3">
            <Row className="mb-4">
              <Col md={6}>
                <h4>Name: {userName}</h4>
              </Col>
              <Col md={6} className="text-md-end">
                <h4>Email: {email}</h4>
              </Col>
            </Row>
            <div className="pt-5">
              <h4 className="mb-5">Quantity Purchased</h4>
              <div style={{ position: "relative" }}>
                <ProgressBar
                  completed={progressPercentage}
                  bgColor={progressBarColor}
                  height="20px"
                  customLabel={progressBarLabel}
                  labelAlignment="center"
                  labelColor="#fff"
                />
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "-35px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  5kg
                </div>
              </div>
            </div>
            <Row className="mt-5">
              <Col md={6}>
                <h4>Exceed Quantity: {list.exceed}</h4>
              </Col>
              <Col md={6} className="text-end">
                {list?.exceed !== 0 && (
                  <>
                    <Button
                      onClick={handleShareButtonClick}
                      className="btn-secondary"
                    >
                      Share
                    </Button>{" "}
                    <Button
                      onClick={() => Sharetonextyear(userId)}
                      className="btn-secondary"
                    >
                      Forward to Next Year
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            {showShareButtons && (
              <div>
                <ShareButtons url={loginUrl} title={shareTitle} />
              </div>
            )}
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Apply Code</th>
                <th>Quantity</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr key={index}>
                  <td>{row.r_code}</td>
                  <td>{row.quantity}</td>
                  <td>{new Date(row.rr_date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="mt-5px text-center">
          <Button onClick={handleLogout} className="btn-danger">
            Logout
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default Home;
