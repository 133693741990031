import React, { useState } from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
  FacebookIcon,
  WhatsappIcon,
  InstapaperIcon,
} from 'react-share';
import { FaCopy } from 'react-icons/fa';

const ShareButtons = ({ url, title, currentPath }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
  };

  const completeUrl = `${url}${currentPath}`;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input type="text" value={completeUrl} readOnly style={{ width: '100%', marginBottom: '10px' }} />
        <button className="btn btn-primary" onClick={copyToClipboard} style={{ marginLeft: '10px' }}>
          <FaCopy /> {copied ? 'Copied!' : 'Copy URL'}
        </button>
      </div>
      <div>
        {/* <FacebookShareButton url={completeUrl} quote={title} style={{ marginLeft: 20 }}>
          <FacebookIcon size={32} round />
        </FacebookShareButton> */}

        <WhatsappShareButton url={completeUrl} title={title} style={{ marginLeft: 20 }}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        {/* <InstapaperShareButton url={completeUrl} title={title} style={{ marginLeft: 20 }}>
          <InstapaperIcon size={32} round />
        </InstapaperShareButton> */}
      </div>
    </div>
  );
};

export default ShareButtons;
