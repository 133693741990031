import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Otp from "./Pages/Otp/Otp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OuterMainpages from './Pages/OuterMainPages/OuterMainpages';
import Homepages from './Pages/Homepage/Homepages';


function App() {
  return (
    <>
     <BrowserRouter>
      <Routes>
      <Route path='/' element={<OuterMainpages/>}/>
     
      <Route path="/Login/:referral_id" element={<Login />} />
      <Route path="/Login" element={<Login />} />
       <Route path='/otp' element={<Otp/>}/>
       <Route path='/register' element={<Register/>}/>
       <Route path='/Homepages' element={<Homepages/>}/>
     </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
