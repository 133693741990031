import axios from "axios";
import "./otp.css";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Logo from "../../Assets/suprabadh Logo 1.png";

const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const email = location.state?.email || "";
  const referral_id = location.state?.referral_id || "";

  const query = new URLSearchParams(useLocation().search);

  // OtpverifyApi
  const Otpverify = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://lunarsenterprises.com:3006/tea/user/login${
          referral_id ? `?referral_id=${referral_id}` : ""
        }`,
        {
          user_email: email,
          user_token: otp,
        }
      );
      console.log(response.data);
      alert(response.data.message);

      if (response.data.result) {
        // Store user_id and token in localStorage
        localStorage.setItem("user_id", response.data.user.user_id);
        localStorage.setItem("token", response.data.token);

        // Navigate to the next page on successful response
        navigate("/Homepages", {
          state: { userName: response.data.user.userName, Email: email },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Resend OTP
  const resendOtp = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      // Send POST request to the API
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/user/mailsend",
        {
          user_email: email, // Email to be sent in the request
        }
      );

      console.log(response.data); // Log the response for debugging

      // Check if the response indicates success
      if (response.data.result === true) {
        alert(response.data.message); // Show success message
        navigate("/otp", { state: { email: email, referral_id: referral_id } });

      } else {
        alert(response.data.message || "Email not registered"); // Show specific message from response or fallback
      }
    } catch (error) {
      console.error("Error sending request:", error); // Log the error

      // Show a generic error message if the request itself fails
      alert("An error occurred while sending the request. Please try again.");
    }
  };
  return (
    <div className="main">
      <div style={{marginTop:-20}}>
          <img src={Logo} width={220} height={100} className="logostyle" alt="logo" />
        </div>
        <div className="text-center move-up">
          <h2 className="titletext ">APNA DESH TEA</h2>
        </div>
      <div className="container-fluid login">
        <form
          onSubmit={Otpverify}
          style={{ padding: "20px", background: "unset", boxShadow: "unset" }}
        >
          <h3 className="text-center">Submit your OTP</h3>
          <div className="log-inputs">
            <div>
              <Form.Label htmlFor="otp">Enter OTP</Form.Label>
              <Form.Control
                type="text"
                name="otp"
                id="otp"
                placeholder="Enter your OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          </div>

          <button type="submit">Submit</button>

          <button type="button" onClick={resendOtp}>
            Resend OTP
          </button>

          <NavLink to="/register">
            <h6>Register Now?</h6>
          </NavLink>
        </form>
      </div>
    </div>
  );
};

export default Otp;
